.nav-wrapper-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: stretch;
  height: 100vh;
  justify-content: flex-start;
}

.nav-route-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  align-self: stretch;
  overflow: hidden;
  justify-content: flex-start;
  padding: 24px 0px;
}
