.metrics-row {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.metrics-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 400;
    margin: 0px;
    color: var(--primary-main);
}

.metrics-squares {
    display: flex;
    flex-direction: row;
}

.datadog-link {
    display: flex;
    font-size: 24px;
    margin-top: 24px;
    margin-bottom: 24px;
    flex-direction: row;
    justify-content: center;
}

.link-text {
    color: var(--primary-main);
}

table {
    max-height: 350px;
    border: 1px solid var(--primary-main);
    text-align: center;
    width: 100%;
    border-collapse: collapse;
    margin-top: 18px;
    display: block;
    overflow-y: scroll;
}

thead { 
    position: sticky;
    top: 0;
    width: 20%;
}

th, td {
    font-size: 20px;
    padding: 8px 16px;
}

th {
   background: var(--primary-main);
   font-weight: 400;
}
