/*
  I have zero idea whats going on here but for some reason during the build script the first css class in this file isn't
  being picked up... So here we have this unused-hack class defined to solve this problem.... weird. Well heres the
  TODO [#46]: [Bug] WEB - CSS Loading Bug
*/
.unused-hack {
  background: transparent;
}

.primary-button {
  /* Main */
  border: 1px solid var(--button-border);
  border-radius: 42px;
  padding: 14px 40px;
  /* gradient */
  background: linear-gradient(266.29deg, #703ADD 4.62%, #8448FF 44.94%, #8448FF 44.95%, #8448FF 44.96%, #AF4FF6 95.73%);
  cursor: pointer;
}

.primary-button:hover {
  background: var(--primary-secondary);
  border: 1px solid var(--primary-secondary);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.primary-button:active {
  background: var(--button-border);
  border: 1px solid var(--button-border);
}

.primary-button:disabled {
  background: var(--surface-10);
  border: 0px;
}

.primary-button-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #FFFFFF;
}

.primary-button-text-disabled {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: var(--on-surface-50);
}

.secondary-button {
  /* Main */
  border: 1px solid var(--button-border);
  border-radius: 42px;
  padding: 14px 40px;
  background: #bc8be7;
  cursor: pointer;
}

.secondary-button:hover {
  background: var(--surface-10);
  border: 1px solid var(--button-border);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.secondary-button:active {
  background: var(--surface-10);
  border: 0px;
}

.secondary-button-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #ffffff; 
}

.tertiary-button {
  /* Main */
  border: none;
  border-radius: 42px;
  padding: 14px 40px;
  background: none;
  cursor: pointer;
}

.tertiary-button-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #B0A0FF;
}

.tertiary-button:hover .tertiary-button-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #FFFFFF;
}
