.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  margin: 8px 0px;
}

.input-icon {
  padding-right: 6px;
  padding-left: 16px;
}

.input-field {
  width: 100%;
  padding: 16px 6px;
  margin: 2px;
  border: none;
  box-shadow: none;
  background: var(--surface-solid-50);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--neutral-90);
}

.input-field:focus {
  outline: none;
  background: var(--surface-variant-80);
}

.input-error-text {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  color: var(--system-error);
}

.input-label-text {  
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: var(--primary-secondary);
}
