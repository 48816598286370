.tournament-details-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    gap: 12px;
    padding-bottom: 10px;
    overflow-y: auto;
}

.tournament-title {
    color: white;
    /* background-color: red; */
    text-align: center;
}

p {
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
}
.tournament-details {
    display: flex;
    flex-direction: row;
    color: white;
    align-content: center;
    gap: 12px;
    flex-wrap: wrap;
}

.info-label {
    font-weight: 500;
}

.tournament-promotion {
    font-weight: 300;
    color: green;
}

.tournament-pred-card {
    background-color: var(--primary-secondary);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-self: stretch;
}
.pred-status {
    background-color: white;
    border-radius: 20px 0 12px 0;
    padding: 8px;
    width: 20%;
    text-align: center;
    font-weight: 600;
}

.pred-string-container {
    padding-top: 1px;
    padding-bottom: 1px;
    padding-right: 20px;
    padding-left: 20px;
    margin: 10px;
}

.predictions-container {
    display:flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    gap: 12px;
}

.completion-status {
    display: flex;
    flex-direction: row;
}
.completed-prediction-bar {
    background-color: var(--primary-secondary);
    height: 32px;
    border-radius: 6px 0 0 6px;
}
.finished-prediction-bar {
    background-color: var(--primary-secondary);
    height: 32px;
    border-radius: 6px;
}
.remaining-prediction-bar {
    background-color: white;
    height: 32px;
    border-radius: 0 6px 6px 0;
}

.tournament-info-container {
    width:100%
}

.more-info-container {
    margin: 12px 0;
    background-color: var(--primary-secondary);
    border-radius: 12px;
    padding: 12px;
}
.more-info-details-container {
    display:flex;
    flex-direction: column;
    align-self: stretch;
    padding: 12px 24px;
    overflow: scroll;
}

.vote-count {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 24px;
}