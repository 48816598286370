.pool-template-details-container {
    padding-bottom: 25px;
}

.pool-template-title {
    font-weight: 600;
    color: white;
}

.pool-template-details {
    font-weight: 300;
    color: white;
}

.pool-prediction {
    background-color: var(--primary-secondary);
    border-radius: 20px;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-right: 20px;
    padding-left: 20px;
    margin: 10px;
}

.predictions-container {
    margin-bottom: 50px;
}