.metric-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 150px;
    background-color: var(--primary-main);
    margin: 10px;
}

.metric-name {
    display: flex;
    color: var(--surface-variant-100);
    font-weight: 450;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    margin: 6px;
}

.metric-value {
    color: var(--surface-variant-100);
    font-weight: 600;
    font-size: 48px;
    justify-content: center;
    flex: 3;
    margin: 6px;
}
