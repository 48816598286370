.add-prediction-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-self: stretch;
    gap: 12px;
    padding: 0px 24px;
    overflow: auto;
}

.template-category-choices {
    display: flex;
    flex-direction: row;
    align-self: stretch;
    justify-content: space-between;
    gap: 48px;
}

.comparison-duration-container {
    flex: 1;
    display: flex;
    align-self: stretch;
    justify-content: space-around;
    gap: 48px;
}

.comparison-duration-selector {
    flex: 1;
}
