.App {
  background-color: #121212;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*font-size: calc(10px + 2vmin);
  color: white; */
}

.App-logo {
  pointer-events: none;
}

a:link {
  color: var(--primary-secondary);
  background-color: transparent;
}

:root {
  /* Primary */
  --primary-main:#9461FF;
  --primary-secondary: #5B3ADD;
  /* Surface */
  --surface-10: rgba(92, 70, 128, 0.85);
  --surface-30: rgba(38, 12, 82, 0.85);
  --surface-50: rgba(21, 9, 51, 0.8);
  --surface-80: rgba(15, 6, 35, 0.9);
  --surface-100: #090617;
  /* Surface Solid */
  --surface-solid-50: #22134F;
  /* On Surface */
  --on-surface-10: #BEB1CE;
  --on-surface-50: #887B98;
  --on-surface-100: #5C4680;
  /* Surface Variant */
  --surface-variant-10: #B7ADE9;
  --surface-variant-30: #9E8BF8;
  --surface-variant-50: #8B73FF;
  --surface-variant-80: #502EB0;
  --surface-variant-100:#33138E;
  /* Neutral */
  --neutral-10: #000000;
  --neutral-20: #322F37;
  --neutral-50: #887B98;
  --neutral-80: #CAC4D0;
  --neutral-90: #E0D0EC;
  --neutral-100: #FFFFFF;
  /* System */
  --system-error: #FF7B7B;
  --system-confirmation: #63C97F;
  /* Un-Categorized - These are used in the mocks but aren't labeled */
  --button-border: #A84EF8;
  /* Third Party Overrides */
  --swiper-theme-color: var(--primary-main) !important;
};
