.add-pool-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    gap: 12px;
    padding-bottom: 10px;
    overflow-y: auto;
}

.pool-communities-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    gap: 48px;
    padding-bottom: 24px;
    padding-top: 24px;
    overflow-y: auto;
}
