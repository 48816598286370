.view-stats-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    gap: 12px;
    padding-bottom: 10px;
    overflow-y: scroll;
}

.view-stats-text {
    color: var(--primary-secondary);
}

.prediction {
    background-color: var(--primary-secondary);
    border-radius: 20px;
    padding: 6px;
    margin-bottom: 10px;
}

.column-flex {
    display: flex;
    flex-direction: row;
}

.challenge-stats-column {
    border: 1px solid white;
    border-radius: 20px;
    padding: 10px;
}

.challenge-info-column {
    margin-left: 20px;
}

.box {
    border: 1px solid var(--primary-secondary);
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 20px;
}

.user-ledger-summary {
    border-top: 1px solid white;
    margin-top: 20px;
    padding-top: 20px;
}
