.form-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.form-preview-container {
  background-color: black;
  border-radius: 8px;
  border: 2px solid var(--primary-main);
  margin-top: 24px;
}

.form-preview-title {
  background-color: var(--primary-main);
  border-radius: 4px 4px 0px 0px;
  padding: 8px;
  text-align: center;
  font-size: 28px;
  font-weight: 600;
}

.form-preview-content {
  padding: 24px;
  font-size: 24px;
}

.form-dropdown-container {
  max-height: 400px;
  overflow-y: auto;
}
.form-step-selector {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.form-step-input {
  min-height: 180px;
  padding: 24px 0px;
}

.display-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;

  font-size: 18px;
  background-color: var(--primary-main);
  width: 24px;
  height: 24px;
  border-radius: 12px;
  color: white;
}

.selected-display-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;

  font-size: 24px;
  font-weight: 600;
  background-color: var(--primary-main);
  width: 36px;
  height: 36px;
  border-radius: 18px;
  color: white;
}

.wizard-string-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* NOTE: The below is a hack */
  gap: 6px;
}

.provided-wiz-str {
  color: var(--primary-main);
  text-decoration: underline;
}

.undefined-wiz-str {
  color: darkgray;
  text-decoration: underline;
}

.challenge-input-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.challenge-input-row {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
}

input[type=range]{
  -webkit-appearance: none;
}

input[type=range]::-webkit-slider-runnable-track {
  height: 5px;
  border-radius: 3px;
  background-color: var(--primary-main);
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: white;
  margin-top: -5px;
}
