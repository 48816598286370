.create-community-form {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #000; /* Black background */
  color: #8448FF; /* Purple text */
  overflow-y: auto
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #8448FF; /* Purple text */
}

.form-group input[type="text"],
.form-group input[type="checkbox"],
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #1A1A1A; /* Darker background for inputs */
  color: #8448FF; /* Purple text */
}

.form-group input[type="checkbox"] {
  width: auto;
  margin-top: 0;
}

.form-group textarea {
  resize: vertical;
  height: 100px;
}

.primary-button {
  margin-top: 20px;
  width: 100%;
  background-color: #8448FF; /* Purple background */
  border: none;
  color: #FFFFFF; /* White text */
  padding: 14px;
  border-radius: 42px;
  cursor: pointer;
  font-weight: bold;
}

.primary-button:hover {
  background-color: #703ADD; /* Slightly darker purple */
}

.primary-button:disabled {
  background-color: #555; /* Disabled background */
  color: #ccc; /* Disabled text */
}


.form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #333; /* Darker background for dropdown */
  color: #8448FF; /* Purple text */
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
}

.form-group select:focus {
  outline: none;
  border-color: #8448FF; /* Highlight border on focus */
}

.form-group select option {
  background-color: #333; /* Darker background for options */
  color: #8448FF; /* Purple text */
}

.form-preview {
  max-width: 600px;
  width: 100%;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #1A1A1A; /* Darker background for the preview */
  color: #8448FF; /* Purple text */
}

.form-preview h3 {
  margin-top: 0;
  color: #8448FF; /* Purple text */
}

.form-preview p {
  margin: 10px 0;
}