.unauthorized-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 70%;
    padding-bottom: 10px;
}

.unauthorized-text {
    color: var(--primary-secondary);
}
