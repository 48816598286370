.pool-preview-container {
    background-color: var(--primary-secondary);
    border-radius: 20px;
    gap: 12px;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-right: 20px;
    padding-left: 20px;
    margin: 20px;
}

.pool-title {
    font-weight: 600;
    color: white;
    margin-bottom: 0;
}

.pool-details {
    font-weight: 300;
    color: white;
}

.pool-promotion {
    font-weight: 450;
    color: var(--system-confirmation);
    margin-top: 0;
}