.view-pool-templates-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    gap: 12px;
    padding-bottom: 10px;
    overflow-y: auto;
}

.view-pools-text {
    color: var(--primary-secondary);
}
