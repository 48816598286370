.ambassador-tracking-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    gap: 12px;
    padding-bottom: 10px;
    overflow-y: auto;
  }
  
  .header-text {
    color: var(--primary-secondary);
    margin-bottom: 20px;
  }
  
  .input-label {
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .tracking-code-input-container {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  