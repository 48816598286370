.selector-container {
  flex: 1;
  padding: 12px 12px;
  border-bottom: 1px solid var(--primary-main);
}

.options-row {
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.option {
  padding: 6px 16px;
  background-color: Transparent;
  color: white;
  border-color: var(--primary-main);
  border-width: 1px;
  border-radius: 6px;
}

.option-selected {
  padding: 6px 16px;
  color: white;
  background-color: var(--primary-main);
  border-width: 1px;
  border-radius: 6px;
}
